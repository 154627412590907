// Default imports
import React, { useState, useEffect } from "react";
import Link from "../Link";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Import SVG
import IconChevronBottom from "../../icons/chevron-bottom-16.svg";

const NavbarMenuItemDropdown = ({ menuItem, onClick }) => {
  const [currentPath, setCurrentPath] = useState(0);
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  });

  return (
    <li
      key={menuItem.id}
      role="menuitem"
      tabIndex="0"
      className={`navbar-menu__item -dropdown ${
        show ? "-expanded" : "-collapsed"
      }`}
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
    >
      <AniLink
        swipe
        key={menuItem.id}
        className={`navbar-menu__link ${
          currentPath === menuItem.path ? "-active" : ""
        }`}
        to={menuItem.path}
        onClick={onClick}
      >
        {menuItem.label}
        <IconChevronBottom className="navbar-menu__icon" />
      </AniLink>

      <div className="navbar-menu__dropdown">
        <div className="navbar-menu__dropdown-header">
          {menuItem.label}
          <IconChevronBottom className="navbar-menu__icon" />
        </div>
        <ul role="menu" tabIndex="0" className="navbar-menu__dropdown-content">
          {menuItem.childItems?.nodes.map((menuItemChild) => {
            return (
              <NavbarMenuItem
                key={menuItemChild.id}
                inDropdown={true}
                menuItem={menuItemChild}
                onClick={onClick}
              />
            );
          })}
        </ul>
      </div>
    </li>
  );
};

const NavbarMenuItemDefault = ({ menuItem, inDropdown, onClick }) => {
  const [currentPath, setCurrentPath] = useState(0);
  useEffect(() => {
    setCurrentPath(window.location.pathname);
  });

  return (
    <li
      key={menuItem.id}
      role="menuitem"
      tabIndex="0"
      className={`navbar-menu__item ${inDropdown ? "-nested" : ""}`}
    >
      <Link
        className={`navbar-menu__link  ${
          currentPath === menuItem.path ? "-active" : ""
        }`}
        to={menuItem.path}
        onClick={onClick}
      >
        {menuItem.label}
      </Link>
    </li>
  );
};

const NavbarMenuItem = ({ menuItem, inDropdown, isActive, onClick }) => {
  if (menuItem.childItems?.nodes.length > 0) {
    return (
      <NavbarMenuItemDropdown
        key={menuItem.id}
        menuItem={menuItem}
        onClick={onClick}
      />
    );
  } else {
    return (
      <NavbarMenuItemDefault
        key={menuItem.id}
        menuItem={menuItem}
        inDropdown={inDropdown}
        onClick={onClick}
      />
    );
  }
};

export default NavbarMenuItem;
