import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import IconChevronBottom from "../icons/chevron-bottom-16.svg";

import ButtonText from "../components/ButtonText";
import Logo from "../images/icon-transparent.svg";
import LogoFull from "../images/logo.svg";

import { cookiesbarCompData } from "../data/components-cookiesbar.data";
import { Link } from "gatsby";
import { conforms } from "lodash";

const NoticeBanner = ({ isBannerOpen, setIsBannerOpen }) => {
  // Set current location from React Router
  const location = useLocation();

  // States
  const [cookies, setCookie, removeCookie] = useCookies(["banner-open"]);

  // Handlers
  const closeBanner = () => {
    setIsBannerOpen(false);
    setCookie("banner-open", "true", { path: "/" });
    acceptGpdr();
  };

  const acceptGpdr = () => {
    // Initialize Analitycs tools (GTM & HotJar)
    setCookie("banner-open", "true", { path: "/" });
    initializeAndTrack(location);
  };

  useLayoutEffect(() => {
    setIsBannerOpen(cookies["banner-open"] !== "true");
  }, [cookies, setIsBannerOpen]);

  return (
    <>
      {isBannerOpen && (
        <div
          className={`notice-banner ${
            isBannerOpen ? "" : "notice-banner__hide"
          }`}
        >
          <p className="notice-banner__title">AlgoTrader is now Wyden</p>
          <ButtonText variant="white notice-banner__button" url="/intelligence/algotrader-becomes-wyden-a-digital-asset-trading-technology-company/">
            READ MORE
          </ButtonText>
          <p className="notice-banner__icon" onClick={closeBanner}></p>
        </div>
      )}
    </>
  );
};

export default NoticeBanner;
