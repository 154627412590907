import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

// Import templates
import NavbarMenuItem from "./NavbarMenuItem";
import { ListSpecialContentItem } from "../ListSpecial";

const NavbarMenu = ({ wpMenuLocation = "MAIN_MENU", onClick, isOpen }) => {
  // Make query
  const queryData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allWpMenu {
          edges {
            node {
              id
              locations
              menuItems {
                nodes {
                  id
                  parentId
                  label
                  path
                  childItems {
                    nodes {
                      id
                      path
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // Filter menu by location
  const menu = queryData.allWpMenu.edges.find((menuItem) => {
    return menuItem.node.locations.includes(wpMenuLocation.toUpperCase());
  });

  // Return if no menu has been found in this location
  if (!menu) {
    console.warn("No menu with this location has been found");
    return;
  }

  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (id) => {
    setActiveItem(id === activeItem ? null : id);
  };
  return (
    <nav className={`navbar-menu ${isOpen ? "-is-open" : ""} `}>
      <div className="navbar-menu__wrapper">
        <ul className="navbar-menu__items">
          {menu.node.menuItems.nodes
            .filter((menuItem) => menuItem.parentId === null)
            .map((menuItem) => {
              return (
                <NavbarMenuItem
                  key={menuItem.id}
                  menuItem={menuItem}
                  onClick={onClick}
                />
              );
            })}
        </ul>
      </div>

      <div
        className={`navbar-menu__mobile-wrapper ${isOpen ? "-show" : "-hide"}`}
      >
        <section className="navbar-menu__mobile-items">
          {menu.node.menuItems.nodes
            .filter((menuItem) => menuItem.parentId === null)
            .map((parentMenuItem, i) =>
              parentMenuItem.childItems?.nodes?.length > 0 ? (
                <ListSpecialContentItem
                  fieldGroupName="Content_Accordion"
                  header={parentMenuItem.label}
                  onClick={() => handleItemClick(i)}
                  folded={i !== activeItem}
                  content={parentMenuItem.childItems.nodes.map(
                    (childMenuItem) => ({
                      fieldGroupName: "Content_AccordionLink",
                      className: "-nested",
                      header: childMenuItem.label,
                      link: {
                        title: childMenuItem.label,
                        url: childMenuItem.path,
                      },
                    })
                  )}
                />
              ) : (
                <ListSpecialContentItem
                  fieldGroupName="Content_AccordionLink"
                  linkClassName="px-0"
                  header={parentMenuItem.label}
                  link={{
                    title: parentMenuItem.label,
                    url: parentMenuItem.path,
                  }}
                />
              )
            )}
        </section>
      </div>
    </nav>
  );
};

export default NavbarMenu;
